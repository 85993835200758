<template>
  <svg :class="'icon-' + className">
    <use :href="'#icon-' + iconName"></use>
  </svg>
</template>
<script>
export default {
  name: "SvgIcon",
  props: {
    iconName: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
  },
  setup() {
    const req = require.context("../../assets/svg", false, /\.svg$/);
    const requireAll = (requireContext) => {
      return requireContext.keys().map(requireContext);
    };
    requireAll(req);
  },
};
</script>
<style lang="scss" scoped>
.icon-svg20 {
  width: 1em;
  height: 1em;
  fill: currentColor;
  font-size: 20px;
  padding-right: 2px;
  margin-bottom: -4px;
}
.icon-svg201 {
  width: 1em;
  height: 1em;
  fill: currentColor;
  font-size: 20px;
}
.icon-svg15 {
  width: 1em;
  height: 1em;
  fill: currentColor;
  font-size: 15px;
  margin-bottom: -2px;
  margin-right: 10px;
}
.icon-svg16 {
  width: 1em;
  height: 1em;
  fill: currentColor;
  font-size: 16px;
  margin-bottom: -2px;
  margin-right: 10px;
}
</style>